import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { AgChartsReact } from 'ag-charts-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export function DashboardProjectTypes({ option }) {
    const numFormatter = new Intl.NumberFormat("en-US");

    const [chartOptions, setChartOptions] = useState({
        data: [],
        series: [
            {
                type: "donut",
                calloutLabelKey: "type",
                angleKey: "count",
                sectorLabelKey: "count",
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numFormatter.format(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [],
                tooltip: {
                    renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                        const total = chartOptions.data.reduce((sum, d) => sum + d["count"], 0);
                        const percentage = ((datum[sectorLabelKey] / total) * 100).toFixed(2);
                        return {
                            title,
                            content: `${datum[calloutLabelKey]}: ${numFormatter.format(datum[sectorLabelKey])} (${percentage}%)`,
                        };
                    },
                },
                strokeWidth: 3,
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = firebase.firestore();
                const projectSnapshot = await db.collection('projects').get();

                const projectData = {
                    "code": 0,
                    "électronique": 0,
                    "3D": 0,
                };

                for (const doc of projectSnapshot.docs) {
                    const historySnapshot = await doc.ref.collection("history").where("type", "==", "creation").get();
                    if (!historySnapshot.empty) {
                        const creationDoc = historySnapshot.docs[0];
                        const creationData = creationDoc.data();
                        const creationDate = creationData.date ? creationData.date.toDate() : null;
                        if (creationDate && creationDate >= new Date(option.start) && creationDate <= new Date(option.end)) {
                            const project = doc.data();
                            if (project.type === "code" || project.type === "electronic" || project.type === "3D") {
                                if (project.type === "electronic") {
                                    projectData["électronique"]++;
                                } else {
                                    projectData[project.type]++;
                                }
                            }
                        }
                    }
                }

                const data = Object.keys(projectData).map(key => ({
                    type: key,
                    count: projectData[key],
                }));

                const total = data.reduce((sum, d) => sum + d["count"], 0);

                setChartOptions((prevOptions) => ({
                    ...prevOptions,
                    data,
                    series: [
                        {
                            ...prevOptions.series[0],
                            innerLabels: [
                                {
                                    text: numFormatter.format(total),
                                    fontSize: 24,
                                },
                                {
                                    text: "Total",
                                    fontSize: 16,
                                    margin: 10,
                                },
                            ],
                            tooltip: {
                                renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                                    const percentage = ((datum[sectorLabelKey] / total) * 100).toFixed(2);
                                    return {
                                        title,
                                        content: `${datum[calloutLabelKey]}: ${numFormatter.format(datum[sectorLabelKey])} (${percentage}%)`,
                                    };
                                },
                            },
                        }
                    ],
                }));
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        if (option.start && option.end) {
            fetchData();
        }
    }, [option.start, option.end]);

    return (
        <Card>
            <CardBody className='text-center'>
                <Typography>Types de projets publiés</Typography>
                <div style={{ width: '100%', height: '300px' }}>
                    <AgChartsReact options={chartOptions} />
                </div>
            </CardBody>
        </Card>
    );
}
