import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button, Typography, CardHeader, CardBody, Input, Timeline, TimelineItem, TimelineConnector, TimelineIcon, TimelineHeader, Spinner, Select, Option } from '@material-tailwind/react';
import { ArrowLeftIcon, TrashIcon, ArrowPathIcon, PlusIcon, ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';


export function MembersPage() {
    const { id } = useParams();
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [author, setAuthor] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedFullName, setEditedFullName] = useState('');
    const [editedTitle, setEditedTitle] = useState('');
    const [editedIsConseil, setEditedIsConseil] = useState(false);
    const [editedInstagram, setEditedInstagram] = useState('');
    const [editedLinkedin, setEditedLinkedin] = useState('');
    const [historyItems, setHistoryItems] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const firestore = firebase.firestore();
        const storageRef = firebase.storage().ref();

        const fetchData = async () => {
            try {
                const staffDoc = await firestore.collection('staff').doc(id).get();
                if (staffDoc.exists) {
                    const staffData = staffDoc.data();
                    if (staffData.img) {
                        try {
                            const imgRef = storageRef.child(staffData.img);
                            const imgUrl = await imgRef.getDownloadURL();
                            setPreviewUrl(imgUrl);
                        } catch (error) {
                            console.error('Error fetching image URL:', error);
                        }
                    }
                    setSelectedStaff(staffData);
                    const historyDoc = await firestore.collection('staff').doc(staffDoc.id).collection('history').where('type', '==', 'creation').limit(1).get();
                    const authorId = historyDoc.docs[0]?.data().author;
                    if (authorId) {
                        const authorQuerySnapshot = await firestore.collection('users').doc(authorId).get();
                        if (authorQuerySnapshot.exists) {
                            const authorData = authorQuerySnapshot.data();
                            setAuthor(authorData);
                        }
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching staff data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        if (selectedStaff) {
            setEditedFullName(selectedStaff.fullName);
            setEditedTitle(selectedStaff.title);
            setEditedIsConseil(selectedStaff.isConseil);
            setEditedInstagram(selectedStaff.instagram);
            setEditedLinkedin(selectedStaff.linkedin);
        }
    }, [selectedStaff]);

    useEffect(() => {
        const firestore = firebase.firestore();
        const fetchHistory = async () => {
            try {
                const historySnapshot = await firestore.collection('staff').doc(id).collection('history').orderBy('date', 'desc').get();
                const historyData = historySnapshot.docs.map(doc => doc.data());
                setHistoryItems(historyData);
            } catch (error) {
                console.error('Error fetching history data:', error);
            }
        };
        fetchHistory();
    }, [id]);

    const [userIUD, setUserIUD] = useState({});

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) {
            const userIud = user.uid;
            setUserIUD(userIud);
        }
    }, []);

    const handleFullNameChange = (e) => setEditedFullName(e.target.value);
    const handleTitleChange = (e) => setEditedTitle(e.target.value);
    const handleIsConseilChange = (e) => setEditedIsConseil(e.target.value === 'true');
    const handleInstagramChange = (e) => setEditedInstagram(e.target.value);
    const handleLinkedinChange = (e) => setEditedLinkedin(e.target.value);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPreviewUrl(fileUrl);
            setSelectedImage(file);
        }
    };

    const handleSaveEventChanges = async () => {
        const db = firebase.firestore();
        const updatedData = {
            fullName: editedFullName,
            title: editedTitle,
            isConseil: editedIsConseil,
            instagram: editedInstagram,
            linkedin: editedLinkedin,
        };

        if (selectedImage) {
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`img/${selectedImage.name}`);
            await fileRef.put(selectedImage);
            updatedData.img = `img/${selectedImage.name}`;
        }

        const cleanedData = Object.fromEntries(
            Object.entries(updatedData).filter(([_, v]) => v !== undefined)
        );

        db.collection('staff').doc(id).update(cleanedData)
            .then(() => {
                setEditMode(false);
                
                const historyRef = db.collection('staff').doc(id).collection('history').doc();
                const historyData = {
                    author: userIUD,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'update'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la mise à jour de l\'historique:', error);
                });
                alert('Mise à jour des données avec succès !');
                navigate('/register/members')

            })
            .catch((error) => {
                console.log('Erreur lors de l\'enregistrement des modifications:', error);
            });
    };

    const handleDeleteStaff = (staffId) => {
        const db = firebase.firestore();
        const updatedData = {
            active: false
        };

        db.collection('staff').doc(staffId).update(updatedData)
            .then(() => {
                setEditMode(false);
                
                const historyRef = db.collection('staff').doc(staffId).collection('history').doc();
                const historyData = {
                    author: userIUD,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'delete'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la suppression :', error);
                });
                alert('Suppression avec succès !');
                navigate('/register/members')
            })
            .catch((error) => {
                alert('Erreur lors de la suppression :', error);
            });
    };

    const getEventTypeLabel = (type) => {
        switch (type) {
            case 'update':
                return 'Mise à jour des informations';
            case 'creation':
                return 'Création et publication';
            case 'delete':
                return 'Suppression';
            case 'recovery':
                return 'Remise en ligne';
            default:
                return '';
        }
    };

    const getEventIconColor = (type) => {
        switch (type) {
            case 'update':
                return '';
            case 'creation':
                return 'green';
            case 'delete':
                return 'red';
            case 'recovery':
                return 'orange';
            default:
                return '';
        }
    };

    const getEventIcon = (type) => {
        switch (type) {
            case 'update':
                return <ArrowPathIcon className="h-5 w-5" />;
            case 'creation':
                return <PlusIcon className="h-5 w-5" />;
            case 'delete':
                return <TrashIcon className="h-5 w-5" />;
            case 'recovery':
                return <ArrowDownTrayIcon className="h-5 w-5" />;
            default:
                return null;
        }
    };

    return (
        <div>
            {loading ? (
                <div className='flex flex-col items-center justify-center min-h-screen'>
                    <Spinner className="h-20 w-20" />
                </div>
            ) : (
                <div className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-8 grid items-center justify-between gap-8 sm:grid-cols-1 md:grid-cols-2">
                            <div className='flex'>
                                <Link to='/register/members'>
                                    <Button variant="text" onClick={() => { setEditMode(false); }}>
                                        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                                    </Button>
                                </Link>
                                <Typography color="gray" className="mt-1 font-normal">
                                    Membre : {selectedStaff ? selectedStaff.fullName : 'Chargement...'}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 gap-2 sm:flex-row md:justify-end">
                                {editMode ? (
                                    <>
                                        <Button variant="secondary" color='red' onClick={() => setEditMode(false)}>
                                            Annuler
                                        </Button>
                                        <Button variant="success" onClick={handleSaveEventChanges}>
                                            Enregistrer
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button variant="primary" onClick={() => setEditMode(true)}>
                                            Modifier
                                        </Button>
                                        <Button variant="outlined" color="red" onClick={() => handleDeleteStaff(id)}>
                                            <TrashIcon strokeWidth={2} className="h-4 w-4" />
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {selectedStaff && (
                            <div className='gap-5 mt-5 mb-5 grid sm:grid-cols-1 md:grid-cols-2'>
                                <div className='gap-1 w-full'>
                                    <div>
                                        <Typography>ID :</Typography>
                                        <Input value={id} disabled />
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>Nom complet :</Typography>
                                        <Input value={editedFullName} onChange={handleFullNameChange} disabled={!editMode} />
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>Titre :</Typography>
                                        <Input value={editedTitle} onChange={handleTitleChange} disabled={!editMode} />
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>Conseil :</Typography>
                                        <Select value={editedIsConseil.toString()} onChange={handleIsConseilChange} disabled={!editMode}>
                                            <Option value="true">Oui</Option>
                                            <Option value="false">Non</Option>
                                        </Select>
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>Instagram :</Typography>
                                        <Input value={editedInstagram} onChange={handleInstagramChange} disabled={!editMode} />
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>LinkedIn :</Typography>
                                        <Input value={editedLinkedin} onChange={handleLinkedinChange} disabled={!editMode} />
                                    </div>
                                </div>
                                <div className='gap-1 w-full'>
                                    <Typography>Image :</Typography>
                                    <div className="relative border-2" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                        {editMode ? (
                                            <>
                                                <img src={previewUrl} alt="Aperçu" className="w-full h-48 object-cover" />
                                                {isHovered && (
                                                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                        <label className="cursor-pointer flex flex-col items-center">
                                                            <ArrowUpTrayIcon className="h-10 w-10 text-white" />
                                                            <input type="file" className="hidden" onChange={handleImageUpload} />
                                                        </label>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <img src={previewUrl} alt="Aperçu" className="w-full h-48 object-cover" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <Typography>Historique :</Typography>
                        <div className="w-full pl-20 pr-20 pt-10">
                            <Timeline>
                                {historyItems.map((item, index) => (
                                    <TimelineItem key={index} className="h-28">
                                        {index !== historyItems.length - 1 && <TimelineConnector className="!w-[78px]" />}
                                        <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                                            <TimelineIcon className="p-3" variant="ghost" color={getEventIconColor(item.type)}>
                                                {getEventIcon(item.type)}
                                            </TimelineIcon>
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="h6" color="blue-gray">
                                                    {getEventTypeLabel(item.type)}
                                                </Typography>
                                                <Typography variant="small" color="gray" className="font-normal">
                                                    Le {item.date && new Date(item.date.toDate()).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })} à {new Date(item.date.toDate()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })} par {author && author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : ''}
                                                </Typography>
                                            </div>
                                        </TimelineHeader>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </div>
                    </CardBody>
                </div>
            )}
        </div>
    );
}
