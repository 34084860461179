import { Link } from "react-router-dom";

import { Button } from "@material-tailwind/react";


export function NotFound() {
    return (
        <div>
            <div className="text-center" style={{ paddingTop: "40px" }}>
                <h1>404 - Page Not Found</h1>
                <Link to="/">
                    <Button color="blue" ripple="light">
                        Retour à l'accueil
                    </Button>
                </Link>
            </div>
        </div>
    )
}
