import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Button, CardHeader, CardBody, CardFooter, Input, Typography } from '@material-tailwind/react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';



export function CreateProject() {
    const [userUid, setUserUid] = useState('');
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [content, setContent] = useState('');
    const [personalQuote, setPersonalQuote] = useState('');
    const [link_subject, setLinkSubject] = useState('');
    const [link_subject_title, setLinkSubjectTitle] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) setUserUid(user.uid);
    }, []);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            console.log('File selected:', file);
            console.log('File URL:', fileUrl);
            setPreviewUrl(fileUrl);
            setSelectedImage(file);
        } else {
            console.log('No file selected');
        }
    };

    const handleSubmit = async () => {
        const db = firebase.firestore();
        const storageRef = firebase.storage().ref();
        let imageUrl = '';

        if (selectedImage) {
            try {
                const fileRef = storageRef.child(`img/${selectedImage.name}`);
                await fileRef.put(selectedImage);
                imageUrl = `img/${selectedImage.name}`;
                console.log('Image uploaded:', imageUrl);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }

        const projectsRef = db.collection('projects');
        projectsRef.add({
            active: true,
            title,
            desc,
            content,
            personalQuote,
            link_subject,
            link_subject_title,
            img: imageUrl
        })
            .then((docRef) => {
                const historyRef = docRef.collection('history').doc();
                historyRef.set({
                    type: 'creation',
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    author: userUid
                })
                    .then(() => {
                        const historyDocId = historyRef.id;
                        const activityLink = `projects/${docRef.id}/history/${historyDocId}`;

                        const userActivityRef = db.collection('users').doc(userUid).collection('activity');
                        userActivityRef.add({
                            doc: activityLink
                        })
                            .then(() => {
                                alert('Nouveau projet publié avec succès !');
                                setTitle('');
                                setDesc('');
                                setContent('');
                                setPersonalQuote('');
                                setLinkSubject('');
                                setLinkSubjectTitle('');
                                setSelectedImage(null);
                                setPreviewUrl('');
                                navigate('/register/projects')
                            })
                            .catch((error) => {
                                console.error("Erreur lors de l'ajout à l'activité de l'utilisateur:", error);
                            });
                    })
                    .catch((error) => {
                        console.error("Erreur lors de l'ajout à l'historique:", error);
                    });
            })
            .catch((error) => {
                console.error("Erreur lors de la publication du projet:", error);
            });
    };

    return (
        <div>
            <CardHeader floated={false} shadow={false} className="rounded-none flex">
                Publier un nouveau projet
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
                <Input label="Titre" value={title} size="lg" onChange={(e) => setTitle(e.target.value)} />
                <Input label="Description courte" value={desc} size="lg" onChange={(e) => setDesc(e.target.value)} />
                <ReactQuill className="mb-20" theme="snow" value={content} onChange={setContent} style={{ height: '100px' }} />
                <Typography variant="small">*Lien hypertexte, ne pas mettre de caractères spéciaux ou espaces</Typography>
                <Input label="Message personnel du créateur" value={personalQuote} onChange={(e) => setPersonalQuote(e.target.value)} />
                <Typography>A laisser vide si non utilisé :</Typography>
                <Input label="Texte bouton externe" value={link_subject_title} onChange={(e) => setLinkSubjectTitle(e.target.value)} />
                <Input label="Lien bouton externe" value={link_subject} onChange={(e) => setLinkSubject(e.target.value)} />
                <Typography>Image du projet :</Typography>
                <div className="relative mb-4" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    {previewUrl && <img src={previewUrl} alt="Aperçu" className="w-full h-48 object-cover" />}
                    <label className="cursor-pointer flex flex-col items-center mt-2">
                        <ArrowUpTrayIcon className="h-10 w-10 text-gray-400" />
                        <input type="file" className="hidden" onChange={handleImageUpload} />
                        {isHovered && <span className="text-gray-400">Télécharger une image</span>}
                    </label>
                </div>
            </CardBody>
            <CardFooter className='flex gap-2'>
                <Link to='/register/news'>
                    <Button variant="outlined" color="red">
                        <span>Annuler</span>
                    </Button>
                </Link>
                <Button variant="gradient" color="green" onClick={handleSubmit}>
                    <span>Publier</span>
                </Button>
            </CardFooter>
        </div>
    );
}