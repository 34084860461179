import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Drawer, IconButton, Typography, List, ListItem, ListItemPrefix, Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { Bars3Icon, ChevronDownIcon, Cog6ToothIcon, PowerIcon, PresentationChartBarIcon, TableCellsIcon } from "@heroicons/react/24/outline";

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



export function DefaultDrawerSidebar() {
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [accordionOpen, setAccordionOpen] = React.useState(0);
    const navigate = useNavigate();

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    const handleOpen = (value) => {
        setAccordionOpen(accordionOpen === value ? 0 : value);
    };

    useEffect(() => {
        const auth = firebase.auth();
        const db = firebase.firestore();

        const fetchUserData = async (uid) => {
            try {
                const userDoc = await db.collection('users').doc(uid).get();
                if (userDoc.exists) {
                    const userData = userDoc.data();
                    setAdmin(userData.isAdmin);
                }
            } catch (error) {
                console.error("Error fetching user data: ", error);
            }
        };

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                fetchUserData(user.uid);
            } else {
                setUser(null);
                setAdmin(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                setUser(null);
                setAdmin(false);
                alert("Vous êtes bien déconnecté");
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleLinkClick = (path) => {
        closeDrawer();
        navigate(path);
    };

    return (
        <div className="fixed bottom-4 right-4 z-50">
            <IconButton size="lg" className="rounded-full" onClick={openDrawer}>
                <Bars3Icon className="h-5 w-5 transition-transform group-hover:rotate-45" />
            </IconButton>
            <Drawer open={open} onClose={closeDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between">
                    <div onClick={() => handleLinkClick('/')}>
                        <div className="mb-2 flex items-center gap-4 p-4">
                            <img src="https://docs.material-tailwind.com/img/logo-ct-dark.png" alt="brand" className="h-8 w-8" />
                            <Typography variant="h5" color="blue-gray">
                                H.U.B - Admin
                            </Typography>
                        </div>
                    </div>
                    <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>
                <div className="w-full shadow-none">
                    <List>
                        <ListItem onClick={() => handleLinkClick('/dashboard')}>
                            <ListItemPrefix>
                                <PresentationChartBarIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            Dashboard
                        </ListItem>
                        <Accordion
                            open={accordionOpen === 2}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${accordionOpen === 2 ? "rotate-180" : ""}`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={accordionOpen === 2}>
                                <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                                    <ListItemPrefix>
                                        <TableCellsIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Registre
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                <List className="p-0">
                                    <ListItem onClick={() => handleLinkClick('/register/news')}>
                                        <ListItemPrefix>
                                        </ListItemPrefix>
                                        Actualités
                                    </ListItem>
                                    <ListItem onClick={() => handleLinkClick('/register/projects')}>
                                        <ListItemPrefix>
                                        </ListItemPrefix>
                                        Projets
                                    </ListItem>
                                    <ListItem onClick={() => handleLinkClick('/register/tools')}>
                                        <ListItemPrefix>
                                        </ListItemPrefix>
                                        Matériel
                                    </ListItem>
                                    <ListItem onClick={() => handleLinkClick('/register/members')}>
                                        <ListItemPrefix>
                                        </ListItemPrefix>
                                        Membres
                                    </ListItem>
                                </List>
                            </AccordionBody>
                        </Accordion>
                        {admin ? (
                            <Accordion
                                open={accordionOpen === 1}
                                icon={
                                    <ChevronDownIcon
                                        strokeWidth={2.5}
                                        className={`mx-auto h-4 w-4 transition-transform ${accordionOpen === 1 ? "rotate-180" : ""}`}
                                    />
                                }
                            >
                                <ListItem className="p-0" selected={accordionOpen === 1}>
                                    <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                                        <ListItemPrefix>
                                            <Cog6ToothIcon className="h-5 w-5" />
                                        </ListItemPrefix>
                                        <Typography color="blue-gray" className="mr-auto font-normal">
                                            Paramètres
                                        </Typography>
                                    </AccordionHeader>
                                </ListItem>
                                <AccordionBody className="py-1">
                                    <List className="p-0">
                                        <ListItem onClick={() => handleLinkClick('/settings/account')}>
                                            <ListItemPrefix>
                                            </ListItemPrefix>
                                            Mon compte
                                        </ListItem>
                                        <ListItem onClick={() => handleLinkClick('/settings/conf')}>
                                            <ListItemPrefix>
                                            </ListItemPrefix>
                                            Configuration
                                        </ListItem>
                                        <ListItem onClick={() => handleLinkClick('/settings/users')}>
                                            <ListItemPrefix>
                                            </ListItemPrefix>
                                            Utilisateurs
                                        </ListItem>
                                    </List>
                                </AccordionBody>
                            </Accordion>
                        ) : (
                            <ListItem onClick={() => handleLinkClick('/settings/account')}>
                                <ListItemPrefix>
                                    <Cog6ToothIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                Mon compte
                            </ListItem>
                        )}
                        <ListItem onClick={handleLogout} className="text-red-500">
                            <ListItemPrefix>
                                <PowerIcon className="h-5 w-5 text-red-500" />
                            </ListItemPrefix>
                            Se déconnecter
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}
