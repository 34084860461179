import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, CardBody, CardHeader, CardFooter, Input, Typography } from '@material-tailwind/react';
import { MagnifyingGlassIcon, NewspaperIcon } from '@heroicons/react/24/outline';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const TABLE_HEAD = ['ID', 'Titre', 'Date', 'Créateur'];


export function AdminNews() {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [newsPerPage] = useState(20);
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [userIUD, setUserIUD] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const db = firebase.firestore();
        const fetchData = async () => {
            try {
                const newsSnapshot = await db.collection('news').orderBy('title').get();
                const newsData = await Promise.all(
                    newsSnapshot.docs.map(async (doc) => {
                        const newsItem = doc.data();
                        const historyDoc = await db.collection('news').doc(doc.id).collection('history').where('type', '==', 'creation').limit(1).get();
                        const creationDate = historyDoc.docs[0]?.data().date;
                        const creationAuthor = historyDoc.docs[0]?.data().author;
                        return {
                            id: doc.id,
                            ...newsItem,
                            eventDate: creationDate,
                            authorId: creationAuthor,
                        };
                    })
                );
                const activeNews = newsData.filter((newsItem) => newsItem.active);
                setNews(activeNews);

                const userSnapshot = await db.collection('users').get();
                const userData = userSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUsers(userData);

                const user = firebase.auth().currentUser;
                setUserIUD(user.uid);

                setLoading(false);  // Stop loading
            } catch (error) {
                console.error('Error retrieving data:', error);
                setLoading(false);  // Stop loading in case of error
            }
        };

        fetchData();
    }, []);

    const filteredNews = news.filter((item) => {
        const normalizedTitle = item.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const normalizedSearchTerm = searchTerm.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return normalizedTitle.includes(normalizedSearchTerm);
    });

    const totalPages = Math.ceil(filteredNews.length / newsPerPage);

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const paginatedNews = filteredNews.slice((currentPage - 1) * newsPerPage, currentPage * newsPerPage);

    return (
        <div className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-8 flex items-center justify-between gap-8">
                    <div>
                        <Typography color="gray" className="mt-1 font-normal">
                            L'ensemble des actualités publiées
                        </Typography>
                    </div>
                    <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                        <Link to='/register/create/news'>
                            <Button className="flex items-center gap-3" size="sm">
                                <NewspaperIcon strokeWidth={2} className="h-4 w-4" /> Publier une actualité
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <div className="w-full md:w-72">
                        <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} onChange={handleSearchInputChange} />
                    </div>
                </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
                {loading ? (
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((title) => (
                                    <th key={title} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {title}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                        </tbody>
                    </table>
                ) : (
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((title) => (
                                    <th key={title} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {title}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedNews.map(({ id, title, eventDate, authorId }, index) => {
                                const isLast = index === paginatedNews.length - 1;
                                const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';
                                const author = users.find(user => user.id === authorId);
                                return (
                                    <tr key={id}>
                                        <td className={classes}>
                                            <Link to={`/register/news/${id}`}>
                                                <div className="flex items-center gap-3">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {id}
                                                    </Typography>
                                                </div>
                                            </Link>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {title}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {eventDate &&
                                                        new Date(eventDate.toDate()).toLocaleDateString('fr-FR', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                            year: 'numeric',
                                                        })}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {author ? `${author.firstName} ${author.lastName}` : ""}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                    Page {currentPage} sur {totalPages}
                </Typography>
                <div className="flex gap-2">
                    <Button
                        variant="outlined"
                        size="sm"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Précédent
                    </Button>
                    <Button
                        variant="outlined"
                        size="sm"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Suivant
                    </Button>
                </div>
            </CardFooter>
        </div>
    );
}
