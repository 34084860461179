import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Card, Typography, List, ListItem, ListItemPrefix, Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { Cog6ToothIcon, PowerIcon, PresentationChartBarIcon, ChevronDownIcon, TableCellsIcon } from "@heroicons/react/24/solid";

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


export function SidebarWithLogo() {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(() => {
    const auth = firebase.auth();
    const db = firebase.firestore();

    const fetchUserData = async (uid) => {
      try {
        const userDoc = await db.collection('users').doc(uid).get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setAdmin(userData.isAdmin);
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUserData(user.uid);
      } else {
        setUser(null);
        setAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setAdmin(false);
        alert("Vous êtes bien déconnecté");
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Card className="fixed h-[calc(100vh)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <Link to='/'>
        <div className="mb-2 flex items-center gap-4 p-4">
          <img src="https://docs.material-tailwind.com/img/logo-ct-dark.png" alt="brand" className="h-8 w-8" />
          <Typography variant="h5" color="blue-gray">
            H.U.B - Admin
          </Typography>
        </div>
      </Link>
      <List>
        <Link to="/dashboard">
          <ListItem>
            <ListItemPrefix>
              <PresentationChartBarIcon className="h-5 w-5" />
            </ListItemPrefix>
            Dashboard
          </ListItem>
        </Link>
        <Accordion
          open={open === 2}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""}`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 2}>
            <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
              <ListItemPrefix>
                <TableCellsIcon className="h-5 w-5"/>
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                Registre
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <Link to="/register/news">
                <ListItem>
                  <ListItemPrefix>
                  </ListItemPrefix>
                  Actualités
                </ListItem>
              </Link>
              <Link to="/register/projects">
                <ListItem>
                  <ListItemPrefix>
                  </ListItemPrefix>
                  Projets
                </ListItem>
              </Link>
              <Link to="/register/tools">
                <ListItem>
                  <ListItemPrefix>
                  </ListItemPrefix>
                  Matériel
                </ListItem>
              </Link>
              <Link to="/register/members">
                <ListItem>
                  <ListItemPrefix>
                  </ListItemPrefix>
                  Membres
                </ListItem>
              </Link>
            </List>
          </AccordionBody>
        </Accordion>
        {admin ? (
          <Accordion
            open={open === 1}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
              />
            }
          >
            <ListItem className="p-0" selected={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                <ListItemPrefix>
                  <Cog6ToothIcon className="h-5 w-5" />
                </ListItemPrefix>
                <Typography color="blue-gray" className="mr-auto font-normal">
                  Paramètres
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                <Link to="/settings/account">
                  <ListItem>
                    <ListItemPrefix>
                    </ListItemPrefix>
                    Mon compte
                  </ListItem>
                </Link>
                <Link to="/settings/conf">
                  <ListItem>
                    <ListItemPrefix>
                    </ListItemPrefix>
                    Configuration
                  </ListItem>
                </Link>
                <Link to="/settings/users">
                  <ListItem>
                    <ListItemPrefix>
                    </ListItemPrefix>
                    Utilisateurs
                  </ListItem>
                </Link>
              </List>
            </AccordionBody>
          </Accordion>
        ) : (
          <Link to="/settings/account">
            <ListItem>
              <ListItemPrefix>
                <Cog6ToothIcon className="h-5 w-5"/>
              </ListItemPrefix>
              Mon compte
            </ListItem>
          </Link>
        )}
        <ListItem onClick={handleLogout} className="text-red-500">
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5 text-red-500" />
          </ListItemPrefix>
          Se déconnecter
        </ListItem>
      </List>
    </Card>
  );
}
