import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Typography, CardHeader, CardBody, Input, Textarea, Timeline, TimelineItem, TimelineConnector, TimelineIcon, TimelineHeader } from '@material-tailwind/react';
import { ArrowLeftIcon, TrashIcon, ArrowPathIcon, PlusIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

export function NewsPage() {
    const { id } = useParams();
    const [selectedNews, setSelectedNews] = useState(null);
    const [author, setAuthor] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedDesc, setEditedDesc] = useState('');
    const [editedContent, setEditedContent] = useState('');
    const [editedPersonalQuote, setEditedPersonalQuote] = useState('');
    const [editedLinkSubjectTitle, setEditedLinkSubjectTitle] = useState('');
    const [editedLinkSubject, setEditedLinkSubject] = useState('');
    const [historyItems, setHistoryItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedNews) {
            setEditedTitle(selectedNews.title);
            setEditedDesc(selectedNews.desc);
            setEditedContent(selectedNews.content);
            setEditedPersonalQuote(selectedNews.personalQuote);
            setEditedLinkSubject(selectedNews.link_subject);
            setEditedLinkSubjectTitle(selectedNews.link_subject_title);
        }
    }, [selectedNews]);

    useEffect(() => {
        const firestore = firebase.firestore();
        const fetchData = async () => {
            try {
                const newsDoc = await firestore.collection('news').doc(id).get();
                if (newsDoc.exists) {
                    const newsData = newsDoc.data();
                    setSelectedNews(newsData);
                    const historyDoc = await firestore.collection('news').doc(newsDoc.id).collection('history').where('type', '==', 'creation').limit(1).get();
                    const authorId = historyDoc.docs[0]?.data().author;
                    if (authorId) {
                        const authorQuerySnapshot = await firestore.collection('users').doc(authorId).get();
                        if (authorQuerySnapshot.exists) {
                            const authorData = authorQuerySnapshot.data();
                            setAuthor(authorData);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const firestore = firebase.firestore();
        const fetchHistory = async () => {
            try {
                const historySnapshot = await firestore.collection('news').doc(id).collection('history').orderBy('date', 'desc').get();
                const historyData = historySnapshot.docs.map(doc => doc.data());
                setHistoryItems(historyData);
            } catch (error) {
                console.error('Error fetching history:', error);
            }
        };
        fetchHistory();
    }, [id]);

    const [userIUD, setUserIUD] = useState({});

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) {
            setUserIUD(user.uid);
        }
    }, []);

    const handleTitleChange = (e) => setEditedTitle(e.target.value);
    const handleDescChange = (e) => setEditedDesc(e.target.value);
    const handleContentChange = (content) => {
        setEditedContent(content);
    };
    const handlePersonalQuote = (e) => setEditedPersonalQuote(e.target.value);
    const handleLinkSubjectChange = (e) => setEditedLinkSubject(e.target.value);
    const handleLinkSubjectTitleChange = (e) => setEditedLinkSubjectTitle(e.target.value);

    const handleSaveEventChanges = () => {
        const db = firebase.firestore();
        const updatedData = {
            title: editedTitle,
            desc: editedDesc,
            content: editedContent,
            link_subject: editedLinkSubject,
            link_subject_title: editedLinkSubjectTitle,
        };

        db.collection('news').doc(id).update(updatedData)
            .then(() => {
                setEditMode(false);
                const historyRef = db.collection('news').doc(id).collection('history').doc();
                const historyData = {
                    author: userIUD,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'update'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la mise à jour de l\'historique:', error);
                });
                alert('Mise à jour des données avec succès !')
                navigate('/register/news')

            })
            .catch((error) => {
                console.log('Erreur lors de l\'enregistrement des modifications:', error)
            });
    };

    const handleDeleteNews = (newsId) => {
        const db = firebase.firestore();
        const updatedData = {
            active: false
        };

        db.collection('news').doc(newsId).update(updatedData)
            .then(() => {
                setEditMode(false);
                const historyRef = db.collection('news').doc(newsId).collection('history').doc();
                const historyData = {
                    author: userIUD,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'delete'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la suppression :', error);
                });
                alert('Suppression avec succès !')
                navigate('/register/news')

            })
            .catch((error) => {
                alert('Erreur lors de la suppression :', error)
            });
    };

    const getEventTypeLabel = (type) => {
        switch (type) {
            case 'update':
                return 'Mise à jour des informations';
            case 'creation':
                return 'Création et publication';
            case 'delete':
                return 'Suppression';
            case 'recovery':
                return 'Remise en ligne';
            default:
                return '';
        }
    };

    const getEventIconColor = (type) => {
        switch (type) {
            case 'update':
                return '';
            case 'creation':
                return 'green';
            case 'delete':
                return 'red';
            case 'recovery':
                return 'orange';
            default:
                return '';
        }
    };

    const getEventIcon = (type) => {
        switch (type) {
            case 'update':
                return <ArrowPathIcon className="h-5 w-5" />;
            case 'creation':
                return <PlusIcon className="h-5 w-5" />;
            case 'delete':
                return <TrashIcon className="h-5 w-5" />;
            case 'recovery':
                return <ArrowDownTrayIcon className="h-5 w-5" />;
            default:
                return null;
        }
    };

    const extractTextFromHTML = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    }

    return (
        <div>
            <div className="h-full w-full">
                <CardHeader floated={false} shadow={false} className="rounded-none">
                    <div className="mb-8 grid items-center justify-between gap-8 sm:grid-cols-1 md:grid-cols-2">
                        <div className='flex'>
                            <Link to='/register/news'>
                                <Button variant="text" onClick={() => { setEditMode(false) }}>
                                    <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                                </Button>
                            </Link>
                            <Typography color="gray" className="mt-1 font-normal">
                                Actualité : {selectedNews ? selectedNews.title : 'Chargement...'}
                            </Typography>
                        </div>
                        <div className="flex shrink-0 gap-2 sm:flex-row md:justify-end">
                            {editMode ? (
                                <>
                                    <Button variant="secondary" color='red' onClick={() => setEditMode(false)}>
                                        Annuler
                                    </Button>
                                    <Button variant="success" onClick={handleSaveEventChanges}>
                                        Enregistrer
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button variant="primary" onClick={() => setEditMode(true)}>
                                        Modifier
                                    </Button>
                                    <Button variant="outlined" color="red" onClick={() => handleDeleteNews(id)}>
                                        <TrashIcon strokeWidth={2} className="h-4 w-4" />
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    {selectedNews ? (
                        <div>
                            <div>
                                <Typography>ID :</Typography>
                                <Input value={id} disabled />
                            </div>
                            <div className='gap-5 mt-5 grid sm:grid-cols-1 md:grid-cols-2'>
                                <div className=' gap-1 w-full'>
                                    <Typography>Titre :</Typography>
                                    <Input value={editedTitle} onChange={handleTitleChange} disabled={!editMode} />
                                </div>
                                <div className=' gap-1 w-full'>
                                    <Typography>Courte description :</Typography>
                                    <Input value={editedDesc} onChange={handleDescChange} disabled={!editMode} />
                                </div>
                            </div>
                            <div className='mt-5'>
                                <Typography>Contenu de l'article :</Typography>
                                {editMode ? (
                                    <ReactQuill className="mb-20" theme="snow" value={editedContent} onChange={handleContentChange} style={{ height: '300px' }} />
                                ) : (
                                    <Textarea value={extractTextFromHTML(editedContent)} disabled></Textarea>
                                )}
                                
                            </div>
                            <div className='gap-5 mt-5'>
                                <Typography>Message de l'auteur :</Typography>
                                <Input value={editedPersonalQuote} onChange={handlePersonalQuote} disabled={!editMode} />
                            </div>
                            <div className='gap-5 mt-5 mb-5 grid sm:grid-cols-1 md:grid-cols-2'>
                                <div className='gap-1 w-full'>
                                    <Typography>Titre du lien affiché :</Typography>
                                    <Input value={editedLinkSubjectTitle} onChange={handleLinkSubjectTitleChange} disabled={!editMode} />
                                </div>
                                <div className='gap-1 w-full'>
                                    <Typography>Lien :</Typography>
                                    <Input value={editedLinkSubject} onChange={handleLinkSubjectChange} disabled={!editMode} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Typography>Chargement...</Typography>
                    )}
                    <Typography>Historique :</Typography>
                    <div className="w-full pl-20 pr-20 pt-10">
                        <Timeline>
                            {historyItems.map((item, index) => (
                                <TimelineItem key={index} className="h-28">
                                    {index !== historyItems.length - 1 && <TimelineConnector className="!w-[78px]" />}
                                    <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                                        <TimelineIcon className="p-3" variant="ghost" color={getEventIconColor(item.type)}>
                                            {getEventIcon(item.type)}
                                        </TimelineIcon>
                                        <div className="flex flex-col gap-1">
                                            <Typography variant="h6" color="blue-gray">
                                                {getEventTypeLabel(item.type)}
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                                Le {item.date && new Date(item.date.toDate()).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })} à {new Date(item.date.toDate()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })} par {author && author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : ''}
                                            </Typography>
                                        </div>
                                    </TimelineHeader>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </div>
                </CardBody>
            </div>
        </div>
    );
}