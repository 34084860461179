import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, CardBody, CardHeader, CardFooter, Input, Typography } from '@material-tailwind/react';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';


const TABLE_HEAD = ['ID', 'Nom', 'Rôle', 'Date d\'adhésion'];


export function AdminMembers() {
    const [documents, setDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [documentsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [userIUD, setUserIUD] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection('staff')
            .get()
            .then(async (querySnapshot) => {
                const documentData = await Promise.all(
                    querySnapshot.docs.map(async (doc) => {
                        const documentItem = doc.data();
                        return {
                            id: doc.id,
                            ...documentItem,
                        };
                    })
                );
                const activeMembers = documentData.filter((documentItem) => documentItem.active)
                setDocuments(activeMembers);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error retrieving documents:', error);
                setIsLoading(false);
            });

        const user = firebase.auth().currentUser;
        if (user) {
            const userIud = user.uid;
            setUserIUD(userIud);
        }
    }, []);

    const filteredDocuments = documents.filter((item) => {
        if (!item.fullName) return false;
        const normalizedTitle = item.fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const normalizedSearchTerm = searchTerm.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return normalizedTitle.includes(normalizedSearchTerm);
    });

    const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const paginatedDocuments = filteredDocuments.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);


    return (
        <div className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-8 flex items-center justify-between gap-8">
                    <div>
                        <Typography color="gray" className="mt-1 font-normal">
                            L'ensemble des membres de l'association
                        </Typography>
                    </div>
                    <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                        <Link to='/register/create/member'>
                            <Button className="flex items-center gap-3" size="sm">
                                <PlusIcon strokeWidth={2} className="h-4 w-4" /> Ajouter un membre
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <div className="w-full md:w-72">
                        <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} onChange={handleSearchInputChange} />
                    </div>
                </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
                {isLoading ? (
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((title) => (
                                    <th key={title} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {title}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                            <td className='p-4'>
                                <div className="flex items-center gap-3">
                                    <Typography as="div" variant="paragraph" className="mb-2 h-2 w-60 rounded-full bg-gray-300">
                                        &nbsp;
                                    </Typography>
                                </div>
                            </td>
                        </tbody>
                    </table>
                ) : (
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((title) => (
                                    <th key={title} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {title}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedDocuments.map(({ id, fullName, title, joinedDate }, index) => {
                                const isLast = index === paginatedDocuments.length - 1;
                                const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';
                                return (
                                    <tr key={id}>
                                        <td className={classes}>
                                            <Link to={`/register/members/${id}`}>
                                                <div className="flex items-center gap-3">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {id}
                                                    </Typography>
                                                </div>
                                            </Link>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {fullName}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {title}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {joinedDate &&
                                                        new Date(joinedDate.toDate()).toLocaleDateString('fr-FR', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                            year: 'numeric',
                                                        })}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                    Page {currentPage} sur {totalPages}
                </Typography>
                <div className="flex gap-2">
                    <Button
                        variant="outlined"
                        size="sm"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Précédent
                    </Button>
                    <Button
                        variant="outlined"
                        size="sm"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Suivant
                    </Button>
                </div>
            </CardFooter>
        </div>
    );
}