import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Spinner } from "@material-tailwind/react";

import { LoginCard } from './components/Login';
import { SidebarWithLogo } from "./components/Sidebar";
import { AdminNews } from "./components/RegisterNews";
import { NewsPage } from "./components/SinglePageNews";
import { AdminProjects } from "./components/RegisterProjects";
import { ProjectPage } from "./components/SinglePageProject"
import { Home } from './components/Home';
import { Dashboard } from './components/dashboard/Dashboard';
import { AdminStuff } from './components/RegisterStuff';
import { StuffPage } from './components/SinglePageStuff';
import { AdminMembers } from './components/RegisterMembers';
import { MembersPage } from './components/SinglePageMembers';
import { AdminUsers } from './components/AdminUsers';
import { UserPage } from './components/SinglePageUser';
import { NotFound } from './components/404';
import { DefaultDrawerSidebar } from './components/DrawerSideBar';

import { CreateNews } from './components/CreateNews'
import { CreateProject } from './components/CreateProject';
import { CreateStuff } from './components/CreateStuff'
import { CreateMember } from './components/CreateMember';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";


function PageTitle({ title }) {
  useEffect(() => {
    document.title = 'H.U.B - ' + title;
  }, [title]);

  return null;
}


function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
}


export default function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(authUser => {
      if (authUser) {
        setUser(authUser);
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      {loading ? (
        <div className='flex flex-col items-center justify-center min-h-screen'>
          <Spinner className="h-20 w-20" />
        </div>
      ) : user ? (
        <Router>
          <div style={{ display: 'flex' }}>
            {windowWidth > 1000 && <SidebarWithLogo />}
            <div className={`w-full ${windowWidth > 1000 ? 'pl-[20rem]' : ''}`}>
              <Routes>
                <Route path="/" element={<><PageTitle title="Accueil" /><Home /></>} />
                <Route path="/dashboard" element={<><PageTitle title="Dashboard" /><Dashboard /></>} />

                <Route path="/register/news" element={<><PageTitle title="Actualités" /><AdminNews /></>} />
                <Route path="/register/news/:id" element={<><PageTitle title="Actualités" /><NewsPage /></>} />
              
                <Route path="/register/projects" element={<><PageTitle title="Projets" /><AdminProjects /></>} />
                <Route path="/register/projects/:id" element={<><PageTitle title="Projets" /><ProjectPage /></>} />

                <Route path="/register/tools" element={<><PageTitle title="Materiel" /><AdminStuff /></>} />
                <Route path="/register/tools/:id" element={<><PageTitle title="Materiel" /><StuffPage /></>} />

                <Route path="/register/members" element={<><PageTitle title="Membres" /><AdminMembers /></>} />
                <Route path="/register/members/:id" element={<><PageTitle title="Membres" /><MembersPage /></>} />

                <Route path='/register/create/news' element={<><PageTitle title="Nouvelle Actualité" /><CreateNews /></>} />
                <Route path='/register/create/project' element={<><PageTitle title="Nouveau Projet" /><CreateProject /></>} />
                <Route path='/register/create/tool' element={<><PageTitle title="Nouveau Matériel" /><CreateStuff /></>} />
                <Route path='/register/create/member' element={<><PageTitle title="Nouveau Matériel" /><CreateMember /></>} />

                <Route path="/settings/users/" element={<><PageTitle title="Membres" /><AdminUsers /></>} />
                <Route path="/settings/users/:id" element={<><PageTitle title="Membres" /><UserPage /></>} />

                <Route path="*" element={<><PageTitle title="Page non trouvée" /><NotFound /></>} />
              </Routes>
            </div>
            {windowWidth <= 1000 && <DefaultDrawerSidebar />}
          </div>
        </Router>
      ) : (
        <LoginCard />
      )}
    </div>
  );
}
