import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Button, CardHeader, CardBody, CardFooter, Input, Typography } from '@material-tailwind/react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



export function CreateNews() {
    const [userUid, setUserUid] = useState('');
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [content, setContent] = useState('');
    const [personalQuote, setPersonalQuote] = useState('');
    const [link_subject, setLinkSubject] = useState('');
    const [link_subject_title, setLinkSubjectTitle] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) setUserUid(user.uid);
    }, []);

    const handleSubmit = () => {
        const db = firebase.firestore();
        const newsRef = db.collection('news');
        newsRef.add({
            active: true,
            title,
            desc,
            content,
            personalQuote,
            link_subject,
            link_subject_title
        })
            .then((docRef) => {
                const historyRef = docRef.collection('history').doc();
                historyRef.set({
                    type: 'creation',
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    author: userUid
                })
                    .then(() => {
                        const historyDocId = historyRef.id;
                        const activityLink = `news/${docRef.id}/history/${historyDocId}`;

                        const userActivityRef = db.collection('users').doc(userUid).collection('activity');
                        userActivityRef.add({
                            doc: activityLink
                        })
                        .then(() => {
                            alert('Nouvelle actualité publiée avec succès !');
                            setTitle('');
                            setDesc('');
                            setContent('');
                            setPersonalQuote('');
                            setLinkSubject('');
                            setLinkSubjectTitle('');
                            navigate('/register/news')
                        })
                        .catch((error) => {
                            console.error("Erreur lors de l'ajout à l'activité de l'utilisateur:", error);
                        });
                    })
                    .catch((error) => {
                        console.error("Erreur lors de l'ajout à l'historique:", error);
                    });
            })
            .catch((error) => {
                console.error("Erreur lors de la publication de l'actualité:", error);
            });
    };

    return (
        <div>
            <CardHeader floated={false} shadow={false} className="rounded-none flex">
                <div className="mb-8 flex justify-between gap-8">
                    <Typography variant='large'>Créer une nouvelle actualité</Typography>
                </div>
            </CardHeader>
            <CardBody>
                <Input label="Titre" value={title} size="lg" onChange={(e) => setTitle(e.target.value)} />
                <div className="pt-5">
                    <Input label="Description courte" value={desc} size="lg" onChange={(e) => setDesc(e.target.value)} />
                </div>
                <div className="pt-5">
                    <ReactQuill className="mb-20" theme="snow" value={content} onChange={setContent} style={{ height: '100px' }} />
                </div>
                <Input label="Message personnel du créateur" value={personalQuote} onChange={(e) => setPersonalQuote(e.target.value)} />
                <Typography className='mt-5'>A laisser vide si non utilisé :</Typography>
                <div className="gap-5 mb-5 grid sm:grid-cols-1 md:grid-cols-2">
                    <Input label="Texte bouton externe" value={link_subject_title} onChange={(e) => setLinkSubjectTitle(e.target.value)} />
                    <Input label="Lien bouton externe*" value={link_subject} onChange={(e) => setLinkSubject(e.target.value)} />
                    <Typography variant="small">*Lien hypertexte, il faut mettre l'intégralité du lien</Typography>
                </div>
            </CardBody>
            <CardFooter className='flex gap-2'>
                <Link to='/register/news'>
                    <Button variant="outlined" color="red">
                        <span>Annuler</span>
                    </Button>
                </Link>
                <Button variant="gradient" color="green" onClick={handleSubmit}>
                    <span>Publier</span>
                </Button>
            </CardFooter>
        </div>
    );
}
