import React, { useState, useEffect } from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';

import { Button, Typography, CardHeader, CardBody, Input, Textarea, Timeline, TimelineItem, TimelineConnector, TimelineIcon, TimelineHeader, Select, Option, Spinner } from '@material-tailwind/react';
import { ArrowLeftIcon, TrashIcon, ArrowPathIcon, PlusIcon, ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';



export function ProjectPage() {
    const { id } = useParams();
    const [selectedProject, setSelectedProject] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedDesc, setEditedDesc] = useState('');
    const [editedContent, setEditedContent] = useState('');
    const [editedPersonalQuote, setEditedPersonalQuote] = useState('');
    const [editedLinkSubjectTitle, setEditedLinkSubjectTitle] = useState('');
    const [editedLinkSubject, setEditedLinkSubject] = useState('');
    const [editedType, setEditedType] = useState('');
    const [historyItems, setHistoryItems] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [authors, setAuthors] = useState({});
    const [userIUD, setUserIUD] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const firestore = firebase.firestore();
        const storageRef = firebase.storage().ref();

        const fetchData = async () => {
            try {
                const projectDoc = await firestore.collection('projects').doc(id).get();
                if (projectDoc.exists) {
                    const projectData = projectDoc.data();
                    if (projectData.img) {
                        try {
                            const imgRef = storageRef.child(projectData.img);
                            const imgUrl = await imgRef.getDownloadURL();
                            setPreviewUrl(imgUrl);
                        } catch (error) {
                            console.error('Error fetching project image URL:', error);
                        }
                    }
                    setSelectedProject(projectData);
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        if (selectedProject) {
            setEditedTitle(selectedProject.title);
            setEditedDesc(selectedProject.desc);
            setEditedContent(selectedProject.content);
            setEditedPersonalQuote(selectedProject.personalQuote);
            setEditedLinkSubject(selectedProject.link_subject);
            setEditedLinkSubjectTitle(selectedProject.link_subject_title);
            setEditedType(selectedProject.type);
        }
    }, [selectedProject]);

    useEffect(() => {
        const firestore = firebase.firestore();
        const fetchHistory = async () => {
            try {
                const historySnapshot = await firestore.collection('projects').doc(id).collection('history').orderBy('date', 'desc').get();
                const historyData = historySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setHistoryItems(historyData);
            } catch (error) {
                console.error('Error fetching history data:', error);
            }
        };
        fetchHistory();
    }, [id]);

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) {
            const userIud = user.uid;
            setUserIUD(userIud);
        }
    }, []);

    useEffect(() => {
        const firestore = firebase.firestore();
        const fetchAuthors = async () => {
            const authorIds = [...new Set(historyItems.map(item => item.author))];
            const authorData = {};
            for (const authorId of authorIds) {
                if (authorId) {
                    const authorDoc = await firestore.collection('users').doc(authorId).get();
                    if (authorDoc.exists) {
                        authorData[authorId] = authorDoc.data();
                    }
                }
            }
            setAuthors(authorData);
        };
        if (historyItems.length > 0) {
            fetchAuthors();
        }
    }, [historyItems]);

    const handleTitleChange = (e) => setEditedTitle(e.target.value);
    const handleDescChange = (e) => setEditedDesc(e.target.value);
    const handleContentChange = (content) => {
        setEditedContent(content);
    };
    const handlePersonalQuote = (e) => setEditedPersonalQuote(e.target.value);
    const handleLinkSubjectChange = (e) => setEditedLinkSubject(e.target.value);
    const handleLinkSubjectTitleChange = (e) => setEditedLinkSubjectTitle(e.target.value);
    const handleTypeChange = (value) => setEditedType(value);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPreviewUrl(fileUrl);
            setSelectedImage(file);
        }
    };

    const handleSaveEventChanges = async () => {
        const db = firebase.firestore();
        const updatedData = {
            title: editedTitle,
            desc: editedDesc,
            content: editedContent,
            link_subject: editedLinkSubject,
            link_subject_title: editedLinkSubjectTitle,
            type: editedType
        };

        if (selectedImage) {
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`img/${selectedImage.name}`);
            await fileRef.put(selectedImage);
            updatedData.img = `img/${selectedImage.name}`;
        }

        const cleanedData = Object.fromEntries(
            Object.entries(updatedData).filter(([_, v]) => v !== undefined)
        );

        db.collection('projects').doc(id).update(cleanedData)
            .then(() => {
                setEditMode(false);
                
                const historyRef = db.collection('projects').doc(id).collection('history').doc();
                const historyData = {
                    author: userIUD,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'update'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la mise à jour de l\'historique:', error);
                });
                alert('Mise à jour des données avec succès !');
                navigate('/register/projects')
            })
            .catch((error) => {
                console.log('Erreur lors de l\'enregistrement des modifications:', error);
            });
    };

    const handleDeleteProject = (projectId) => {
        const db = firebase.firestore();
        const updatedData = {
            active: false
        };

        db.collection('projects').doc(projectId).update(updatedData)
            .then(() => {
                setEditMode(false);
                
                const historyRef = db.collection('projects').doc(projectId).collection('history').doc();
                const historyData = {
                    author: userIUD,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'delete'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la suppression :', error);
                });
                alert('Suppression avec succès !');
                navigate('/register/projects')

            })
            .catch((error) => {
                alert('Erreur lors de la suppression :', error);
            });
    };

    const getEventTypeLabel = (type) => {
        switch (type) {
            case 'update':
                return 'Mise à jour des informations';
            case 'creation':
                return 'Création et publication';
            case 'delete':
                return 'Suppression';
            case 'recovery':
                return 'Remise en ligne';
            default:
                return '';
        }
    };

    const getEventIconColor = (type) => {
        switch (type) {
            case 'update':
                return '';
            case 'creation':
                return 'green';
            case 'delete':
                return 'red';
            case 'recovery':
                return 'orange';
            default:
                return '';
        }
    };

    const getEventIcon = (type) => {
        switch (type) {
            case 'update':
                return <ArrowPathIcon className="h-5 w-5" />;
            case 'creation':
                return <PlusIcon className="h-5 w-5" />;
            case 'delete':
                return <TrashIcon className="h-5 w-5" />;
            case 'recovery':
                return <ArrowDownTrayIcon className="h-5 w-5" />;
            default:
                return null;
        }
    };

    const extractTextFromHTML = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    }

    return (
        <div>
            {selectedProject ? (
                <div className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-8 grid items-center justify-between gap-8 sm:grid-cols-1 md:grid-cols-2">
                            <div className='flex'>
                                <Link to='/register/projects'>
                                    <Button variant="text" onClick={() => { setEditMode(false); }}>
                                        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                                    </Button>
                                </Link>
                                <Typography color="gray" className="mt-1 font-normal">
                                    Projet : {selectedProject.title}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 gap-2 sm:flex-row md:justify-end">
                                {editMode ? (
                                    <>
                                        <Button variant="secondary" color='red' onClick={() => setEditMode(false)}>
                                            Annuler
                                        </Button>
                                        <Button variant="success" onClick={handleSaveEventChanges}>
                                            Enregistrer
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button variant="primary" onClick={() => setEditMode(true)}>
                                            Modifier
                                        </Button>
                                        <Button variant="outlined" color="red" onClick={() => handleDeleteProject(id)}>
                                            <TrashIcon strokeWidth={2} className="h-4 w-4" />
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {selectedProject && (
                            <div>
                                <div>
                                    <Typography>ID :</Typography>
                                    <Input value={id} disabled />
                                </div>
                                <div className='gap-5 mt-5 grid sm:grid-cols-1 md:grid-cols-2'>
                                    <div className=' gap-1 w-full'>
                                        <Typography>Titre :</Typography>
                                        <Input value={editedTitle} onChange={handleTitleChange} disabled={!editMode} />
                                    </div>
                                    <div className=' gap-1 w-full'>
                                        <Typography>Courte description :</Typography>
                                        <Input value={editedDesc} onChange={handleDescChange} disabled={!editMode} />
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <Typography>Contenu du projet :</Typography>
                                    {editMode ? (
                                        <ReactQuill className="mb-20" theme="snow" value={editedContent} onChange={handleContentChange} style={{ height: '300px' }} />
                                    ) : (
                                        <Textarea value={extractTextFromHTML(editedContent)} disabled />
                                    )}
                                </div>
                                <div className='gap-5 mt-5'>
                                    <Typography>Message du maker :</Typography>
                                    <Input value={editedPersonalQuote} onChange={handlePersonalQuote} disabled={!editMode} />
                                </div>
                                <div className='gap-5 mt-5 mb-5 grid sm:grid-cols-1 md:grid-cols-2'>
                                    <div className='gap-1 w-full'>
                                        <div>
                                            <Typography>Type de projet</Typography>
                                            <Select
                                                value={editedType}
                                                onChange={(value) => handleTypeChange(value)}
                                                disabled={!editMode}
                                            >
                                                <Option value="electronic">Électronique</Option>
                                                <Option value="code">Code</Option>
                                            </Select>
                                        </div>
                                        <div className='gap-5 mt-5'>
                                            <Typography>Titre du lien affiché</Typography>
                                            <Input value={editedLinkSubjectTitle} onChange={handleLinkSubjectTitleChange} disabled={!editMode} />
                                        </div>
                                        <div className='gap-5 mt-5'>
                                            <Typography>Lien :</Typography>
                                            <Input value={editedLinkSubject} onChange={handleLinkSubjectChange} disabled={!editMode} />
                                        </div>
                                    </div>
                                    <div className='gap-1 w-full'>
                                        <Typography>Image :</Typography>
                                        <div className="relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                            {editMode ? (
                                                <>
                                                    <img src={previewUrl} alt="Aperçu border-2" className="w-full h-48 object-cover" />
                                                    {isHovered && (
                                                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                            <label className="cursor-pointer flex flex-col items-center">
                                                                <ArrowUpTrayIcon className="h-10 w-10 text-white" />
                                                                <input type="file" className="hidden" onChange={handleImageUpload} />
                                                            </label>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <img src={previewUrl} alt="Aperçu" className="w-full h-48 object-cover" />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Typography>Historique :</Typography>
                        <div className="w-full pl-20 pr-20 pt-10">
                            <Timeline>
                                {historyItems.map((item, index) => (
                                    <TimelineItem key={index} className="h-28">
                                        {index !== historyItems.length - 1 && <TimelineConnector className="!w-[78px]" />}
                                        <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                                            <TimelineIcon className="p-3" variant="ghost" color={getEventIconColor(item.type)}>
                                                {getEventIcon(item.type)}
                                            </TimelineIcon>
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="h6" color="blue-gray">
                                                    {getEventTypeLabel(item.type)}
                                                </Typography>
                                                <Typography variant="small" color="gray" className="font-normal">
                                                    Le {item.date && new Date(item.date.toDate()).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })} à {new Date(item.date.toDate()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })} par {authors[item.author] ? `${authors[item.author].firstName} ${authors[item.author].lastName}` : ''}
                                                </Typography>
                                            </div>
                                        </TimelineHeader>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </div>
                    </CardBody>
                </div>
            ) : (
                <div className='flex flex-col items-center justify-center min-h-screen'>
                    <Spinner className="h-20 w-20" />
                </div>
            )}
        </div>
    );
}
