import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Button, CardHeader, CardBody, CardFooter, Input, Typography, Switch } from '@material-tailwind/react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export function CreateMember() {
    const [userUid, setUserUid] = useState('');
    const [fullName, setFullName] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isConseil, setIsConseil] = useState(false);
    const [joinedDate, setJoinedDate] = useState('');
    const [title, setTitle] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) setUserUid(user.uid);
    }, []);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPreviewUrl(fileUrl);
            setSelectedImage(file);
        }
    };

    const handleSubmit = async () => {
        const db = firebase.firestore();
        const storageRef = firebase.storage().ref();

        let imageUrl = '';
        if (selectedImage) {
            const fileRef = storageRef.child(`img/${selectedImage.name}`);
            await fileRef.put(selectedImage);
            imageUrl = `img/${selectedImage.name}`;
        }

        const membersRef = db.collection('staff');
        membersRef.add({
            active: true,
            fullName,
            img: imageUrl,
            isConseil,
            joinedDate: firebase.firestore.Timestamp.fromDate(new Date(joinedDate)),
            title
        })
            .then((docRef) => {
                const historyRef = docRef.collection('history').doc();
                historyRef.set({
                    type: 'creation',
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    author: userUid
                })
                    .then(() => {
                        const historyDocId = historyRef.id;
                        const activityLink = `staff/${docRef.id}/history/${historyDocId}`;

                        const userActivityRef = db.collection('users').doc(userUid).collection('activity');
                        userActivityRef.add({
                            doc: activityLink
                        })
                        .then(() => {
                            setFullName('');
                            setSelectedImage(null);
                            setPreviewUrl('');
                            setIsConseil(false);
                            setJoinedDate('');
                            setTitle('');
                            alert('Nouveau membre publié avec succès !');
                            navigate('/register/members')
                        })
                        .catch((error) => {
                            console.error("Erreur lors de l'ajout à l'activité de l'utilisateur:", error);
                        });
                    })
                    .catch((error) => {
                        console.error("Erreur lors de l'ajout à l'historique:", error);
                    });
            })
            .catch((error) => {
                console.error("Erreur lors de la publication du membre:", error);
            });
    };

    return (
        <div>
            <CardHeader floated={false} shadow={false} className="rounded-none flex">
                Publier du matériel
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
                <Input label="Nom complet" value={fullName} size="lg" onChange={(e) => setFullName(e.target.value)} />
                <Input label="Titre" value={title} size="lg" onChange={(e) => setTitle(e.target.value)} />
                <Switch label="Conseil" checked={isConseil} onChange={(e) => setIsConseil(e.target.checked)} />
                <Input label="Date d'adhésion" type="date" value={joinedDate} size="lg" onChange={(e) => setJoinedDate(e.target.value)} />
                <Typography>Image :</Typography>
                <div className="relative mb-4" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    {previewUrl && <img src={previewUrl} alt="Aperçu" className="w-full h-48 object-cover" />}
                    <label className="cursor-pointer flex flex-col items-center mt-2">
                        <ArrowUpTrayIcon className="h-10 w-10 text-gray-400" />
                        <input type="file" className="hidden" onChange={handleImageUpload} />
                        {isHovered && <span className="text-gray-400">Télécharger une image</span>}
                    </label>
                </div>
            </CardBody>
            <CardFooter className='flex gap-2'>
                <Link to='/register/tools'>
                    <Button variant="outlined" color="red">
                        <span>Annuler</span>
                    </Button>
                </Link>
                <Button variant="gradient" color="green" onClick={handleSubmit}>
                    <span>Publier</span>
                </Button>
            </CardFooter>
        </div>
    );
}
