import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button, Typography, CardHeader, CardBody, Input, Timeline, TimelineItem, TimelineConnector, TimelineIcon, TimelineHeader, Spinner } from '@material-tailwind/react';
import { ArrowLeftIcon, TrashIcon, ArrowPathIcon, PlusIcon, ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';


export function StuffPage() {
    const { id } = useParams();
    const [selectedStuff, setSelectedStuff] = useState(null);
    const [author, setAuthor] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [editedDesc, setEditedDesc] = useState('');
    const [historyItems, setHistoryItems] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userUID, setUserUID] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserUID = () => {
            const user = firebase.auth().currentUser;
            if (user) {
                setUserUID(user.uid);
            }
        };

        const fetchData = async () => {
            try {
                const firestore = firebase.firestore();
                const storageRef = firebase.storage().ref();

                const stuffDoc = await firestore.collection('stuff').doc(id).get();
                if (stuffDoc.exists) {
                    const stuffData = stuffDoc.data();
                    try {
                        const imgRef = storageRef.child(stuffData.img);
                        const imgUrl = await imgRef.getDownloadURL();
                        setPreviewUrl(imgUrl);
                    } catch (error) {
                        console.error('Error fetching image URL:', error);
                    }
                    setSelectedStuff(stuffData);
                    const historyDoc = await firestore.collection('stuff').doc(stuffDoc.id).collection('history').where('type', '==', 'creation').limit(1).get();
                    const authorId = historyDoc.docs[0]?.data().author;
                    if (authorId) {
                        const authorDoc = await firestore.collection('users').doc(authorId).get();
                        if (authorDoc.exists) {
                            const authorData = authorDoc.data();
                            setAuthor(authorData);
                        }
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchUserUID();
        fetchData();
    }, [id]);

    useEffect(() => {
        if (selectedStuff) {
            setEditedName(selectedStuff.title);
            setEditedDesc(selectedStuff.desc);
        }
    }, [selectedStuff]);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const firestore = firebase.firestore();
                const historySnapshot = await firestore.collection('stuff').doc(id).collection('history').orderBy('date', 'desc').get();
                const historyData = historySnapshot.docs.map(doc => doc.data());
                setHistoryItems(historyData);
            } catch (error) {
                console.error('Error fetching history:', error);
            }
        };

        fetchHistory();
    }, [id]);

    const handleNameChange = (e) => setEditedName(e.target.value);
    const handleDescChange = (e) => setEditedDesc(e.target.value);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPreviewUrl(fileUrl);
            setSelectedImage(file);
        }
    };

    const handleSaveEventChanges = async () => {
        const db = firebase.firestore();
        const updatedData = {
            title: editedName,
            desc: editedDesc,
        };

        if (selectedImage) {
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`img/${selectedImage.title}`);
            await fileRef.put(selectedImage);
            updatedData.img = `img/${selectedImage.title}`;
        }

        db.collection('stuff').doc(id).update(updatedData)
            .then(() => {
                setEditMode(false);
                const historyRef = db.collection('stuff').doc(id).collection('history').doc();
                const historyData = {
                    author: userUID,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'update'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la mise à jour de l\'historique:', error);
                });
                alert('Mise à jour des données avec succès !');
                navigate('/register/tools')
            })
            .catch((error) => {
                console.log('Erreur lors de l\'enregistrement des modifications:', error);
            });
    };

    const handleDeleteStuff = (stuffId) => {
        const db = firebase.firestore();
        const updatedData = {
            active: false
        };

        db.collection('stuff').doc(stuffId).update(updatedData)
            .then(() => {
                setEditMode(false);
                const historyRef = db.collection('stuff').doc(stuffId).collection('history').doc();
                const historyData = {
                    author: userUID,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'delete'
                };
                historyRef.set(historyData).then(() => {
                    console.log('Historique mis à jour avec succès !');
                }).catch((error) => {
                    console.error('Erreur lors de la suppression :', error);
                });
                alert('Suppression avec succès !');
                navigate('/register/tools')
            })
            .catch((error) => {
                alert('Erreur lors de la suppression :', error);
            });
    };

    const getEventTypeLabel = (type) => {
        switch (type) {
            case 'update':
                return 'Mise à jour des informations';
            case 'creation':
                return 'Création et publication';
            case 'delete':
                return 'Suppression';
            case 'recovery':
                return 'Remise en ligne';
            default:
                return '';
        }
    };

    const getEventIconColor = (type) => {
        switch (type) {
            case 'update':
                return '';
            case 'creation':
                return 'green';
            case 'delete':
                return 'red';
            case 'recovery':
                return 'orange';
            default:
                return '';
        }
    };

    const getEventIcon = (type) => {
        switch (type) {
            case 'update':
                return <ArrowPathIcon className="h-5 w-5" />;
            case 'creation':
                return <PlusIcon className="h-5 w-5" />;
            case 'delete':
                return <TrashIcon className="h-5 w-5" />;
            case 'recovery':
                return <ArrowDownTrayIcon className="h-5 w-5" />;
            default:
                return null;
        }
    };

    return (
        <div>
            {loading ? (
                <div className='flex flex-col items-center justify-center min-h-screen'>
                    <Spinner className="h-20 w-20" />
                </div>
            ) : (
                <div className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-8 grid items-center justify-between gap-8 sm:grid-cols-1 md:grid-cols-2">
                            <div className='flex'>
                                <Link to='/register/tools'>
                                    <Button variant="text" onClick={() => { setEditMode(false); }}>
                                        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                                    </Button>
                                </Link>
                                <Typography color="gray" className="mt-1 font-normal">
                                    Objet : {selectedStuff?.title}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 gap-2 sm:flex-row md:justify-end">
                                {editMode ? (
                                    <>
                                        <Button variant="secondary" color='red' onClick={() => setEditMode(false)}>
                                            Annuler
                                        </Button>
                                        <Button variant="success" onClick={handleSaveEventChanges}>
                                            Enregistrer
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button variant="primary" onClick={() => setEditMode(true)}>
                                            Modifier
                                        </Button>
                                        <Button variant="outlined" color="red" onClick={() => handleDeleteStuff(id)}>
                                            <TrashIcon strokeWidth={2} className="h-4 w-4" />
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {selectedStuff && (
                            <div className='gap-5 mt-5 mb-5 grid sm:grid-cols-1 md:grid-cols-2'>
                                <div className='gap-1 w-full'>
                                    <div>
                                        <Typography>ID :</Typography>
                                        <Input value={id} disabled />
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>Nom :</Typography>
                                        <Input value={editedName} onChange={handleNameChange} disabled={!editMode} />
                                    </div>
                                    <div className=' gap-1 w-full mt-5'>
                                        <Typography>Courte description :</Typography>
                                        <Input value={editedDesc} onChange={handleDescChange} disabled={!editMode} />
                                    </div>
                                </div>
                                <div className='gap-1 w-full'>
                                    <Typography>Image :</Typography>
                                    <div className="relative border-2" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                        <img src={previewUrl} alt="Aperçu" className="w-full h-48 object-cover" />
                                        {editMode && isHovered && (
                                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                <label className="cursor-pointer flex flex-col items-center">
                                                    <ArrowUpTrayIcon className="h-10 w-10 text-white" />
                                                    <input type="file" className="hidden" onChange={handleImageUpload} />
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <Typography>Historique :</Typography>
                        <div className="w-full pl-20 pr-20 pt-10">
                            <Timeline>
                                {historyItems.map((item, index) => (
                                    <TimelineItem key={index} className="h-28">
                                        {index !== historyItems.length - 1 && <TimelineConnector className="!w-[78px]" />}
                                        <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
                                            <TimelineIcon className="p-3" variant="ghost" color={getEventIconColor(item.type)}>
                                                {getEventIcon(item.type)}
                                            </TimelineIcon>
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="h6" color="blue-gray">
                                                    {getEventTypeLabel(item.type)}
                                                </Typography>
                                                <Typography variant="small" color="gray" className="font-normal">
                                                    Le {item.date && new Date(item.date.toDate()).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })} à {item.date && new Date(item.date.toDate()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })} par {author?.firstName && author?.lastName ? `${author.firstName} ${author.lastName}` : ''}
                                                </Typography>
                                            </div>
                                        </TimelineHeader>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </div>
                    </CardBody>
                </div>
            )}
        </div>
    );
}
